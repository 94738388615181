import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import {
  marketingHero,
  marketingTrusted,
  marketingWhiteGlove,
  marketingLargeFeatures,
  marketingBusinessTypesCar,
  marketingVideo,
  marketingSolutionsSection,
  marketingSolutionsSlides,
  marketingTestimonials,
  marketingCTA,
  // marketingFaqs,
} from "../../../data/product-pages/marketing-data";
import Hero from "../../../components/Hero/hero";
// import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";
// import WhiteGlove from "../../../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import BusinessTypes from "../../../components/BusinessTypes/BusinessTypes";
// import VideoSection from "../../../components/VideoSection/VideoSection";
// import PartnerLogos from "../../../components/PartnerLogos/PartnerLogos";
// import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
// import LargeCta from "../../../components/CTAs/LargeCta";
// import ProductLines from "../../../components/ProductLines/ProductLines";
// import SuccessStories from "../../../components/SuccessStories/SuccessStories";
import { argantiSlides, heroSuccess } from "../../../data/success-stories-data";
import ogImage from "../../../images/global_assets/og-image.png";

const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const BusinessTypes = loadable(() =>
  import("../../../components/BusinessTypes/BusinessTypes")
);
const VideoSection = loadable(() =>
  import("../../../components/VideoSection/VideoSection")
);
const PartnerLogos = loadable(() =>
  import("../../../components/PartnerLogos/PartnerLogos")
);
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const ProductLines = loadable(() =>
  import("../../../components/ProductLines/ProductLines")
);
const SuccessStories = loadable(() =>
  import("../../../components/SuccessStories/SuccessStories")
);

const whiteGloveImport = "marketing-pp-wg.png";

const marketing = () => {
  return (
    <Layout>
      <SEO
        title="Marketing Automation Tools For Businesses | SpotOn"
        description="Grow your customer base with SpotOn's marketing automation tools. Our solutions include email marketing automation, integrated payments,  customer data collection & more. Get a demo!"
        image={`https://spoton.com/${ogImage}`}
        cannonical="https://www.spoton.com/products/marketing/"
      />
      <Hero sectionData={marketingHero} circleBg={false} />
      <TrustedNumbers numbersArray={marketingTrusted} />
      <WhiteGlove
        sectionData={marketingWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={marketingLargeFeatures}
        style={{ marginTop: "128px" }}
      />
      <BusinessTypes sectionData={marketingBusinessTypesCar} />
      <SuccessStories sectionData={heroSuccess} slides={argantiSlides} />
      <VideoSection sectionData={marketingVideo} />
      <PartnerLogos />
      <ProductLines
        sectionData={marketingSolutionsSection}
        productSlides={marketingSolutionsSlides}
      />
      <TestmonialReviews sectionData={marketingTestimonials} />
      <PartnerLogos />
      <LargeCta sectionData={marketingCTA} />
      {/* <Faqs sectionData={marketingFaqs} /> */}
    </Layout>
  );
};

export default marketing;
