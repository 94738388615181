// eslint-disable-next-line import/prefer-default-export
export const marketingHero = {
  title: "Grow your customer base with marketing automation tools",
  subtext:
    "SpotOn Marketing makes it easy to grow your customer base and keep them coming back.",
  heroImg: "marketing-hero.png",
  imageClassName: "fixed-height fixed-width top-adjustement",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/marketing/demo",
  },
  fatTitle: "Marketing",
};

export const marketingTrusted = [
  {
    title: "300m+",
    subtext: "Marketing campaigns sent using SpotOn",
  },
  {
    title: "5m+",
    subtext: "Redemptions with SpotOn services and growing",
  },
  {
    title: "$42",
    subtext:
      "Average return you can expect for every $1 you spend on email marketing",
  },
  {
    title: "61%",
    subtext:
      "People want emails from business rather than other types of messaging",
  },
];

export const marketingWhiteGlove = {
  mainTitle: "Digital tools backed by 24/7 personal support",
  title: "Grow your business with someone you can rely on",
  content:
    "With everything you do to run a business, it’s hard to find the time to promote it. We’re here to help. Our team will set you up with an integrated marketing solution so you can grow your customer base by collecting contact information during checkout, and then stay connected with customers to drive repeat business. Not only is our digital marketing dashboard easy to use, our team is here to help you anytime with local service and 24/7 support.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/marketing/demo",
  },
};

export const marketingLargeFeatures = {
  sectionTitle: "Connecting with customers has never been easier",
  featureBlocks: [
    {
      blockTitle: "Create & send marketing campaigns",
      blockImg: "marketing-a.png",
      blockSubTitle:
        "Design campaigns like a professional with our easy-to-use drag-and-drop editor, from any computer or even your mobile phone. Create your own campaign or customize one of our  premade templates to save time.",
      blockList: [
        "Email marketing",
        "Limited-time deals",
        "Facebook campaigns",
        "Customizable branding",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/marketing/demo",
      },
    },
    {
      blockTitle: "Built-in tools to work faster & smarter",
      blockSubTitle:
        "Keep in touch with customers without lifting a finger, thanks to automated campaign tools and an intuitive dashboard.",
      blockImg: "marketing-b.png",
      blockList: [
        "Save time with automated campaigns to drive repeat visits",
        "Track marketing performance with easy-to-understand reports",
        "Do it all from one intuitive dashboard",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/marketing/demo",
      },
    },
    {
      blockTitle: "Grow your customer base",
      blockSubTitle:
        "The SpotOn platform makes it easy for you to gather contact information from customers so you can reach them anytime. Whether it’s a holiday sale, the launch of a new product, or an update about new business hours, SpotOn Marketing helps you keep in touch so they keep coming back.",
      blockImg: "marketing-c.png",
      blockList: [
        "Import your existing customer list",
        "Capture info at the point of sale",
        "Offer an enrollment deal as an incentive",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/marketing/demo",
      },
    },
  ],
};

export const marketingBusinessTypesCar = {
  title: "No marketing degree required",
  subtext:
    "Save time and money and look like a marketing pro when engaging your customers with a simple, non-technical marketing solution.",
  slides: [
    {
      bg: "restaurant.png",
      cardTitle: "Restaurants",
      cardContent: "Fine Dining, Casual Dining, QSR, Food Stores, Food Trucks",
      cardIcon: "cart.svg",
      icon: "restaurant.png",
      linkTo: "/restaurant-pos",
    },
    {
      bg: "auto.png",
      cardTitle: "Automotive",
      cardContent:
        "Oil Change Shops, Auto Parts, Auto Body, Repair, Tire Shops, Tint Shops",
      icon: "automotive.png",
      linkTo: "/automotive",
    },
    {
      bg: "beauty.png",
      cardTitle: "Health & beauty",
      cardContent:
        "Nail/Hair Salons, Tanning Salons, Barbers, Dentists, Veterinarian",
      icon: "health-and-beauty.png",
      linkTo: "/health-and-beauty",
    },
    {
      bg: "professional.png",
      cardTitle: "Professional Services",
      cardContent:
        "Lawyer, Accountant, Tow Truck, Architect, Plumber, Appraiser",
      icon: "prof-services.png",
      linkTo: "/professional-services",
    },
  ],
};

export const marketingVideo = {
  title: "Don’t just send emails. Connect with your customers",
  videoBtn: "Watch video",
  posterImg: "marketing-poster.png",
  videoUrlId: "iSpoRZmN9D4",
};

export const marketingSolutionsSection = {
  title: "Get more from SpotOn Marketing with these integrated tools",
  subText: `SpotOn’s other tools are designed to work hand-in-hand with SpotOn Marketing by helping you grow your customer list.`,
  SecondaryCta: {
    ctaTitle: "View all",
    ctaTarget: "/page-2",
  },
};

export const marketingTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const marketingIntegrations = {
  title: "Integrations",
  subtext:
    "Sync all kind of third party applications and service with SpotOn and automate your revenue growth, retention and marketing processes.",
  integrationsContent: [
    {
      title: "Compatible with SpotOn Register.",
      subtext:
        "Run and grow your business more efficiently than ever before with our cutting-edge point-of-sale solution.",
      contentImg: "register.png",
      ImgClassName: "register",
      btnInfo: {
        ctaTitle: "How it Works",
        ctaTarget: "/products/marketing/demo",
      },
    },
    {
      title: "Out of the box Stripe integration",
      subtext:
        "Accept marketings and move money globally with Stripe’s powerful APIs and software solutions",
      contentImg: "phone.png",
      ImgClassName: "phone",
      btnInfo: {
        ctaTitle: "Read More",
        ctaTarget: "/products/marketing/demo",
      },
    },
  ],
};

export const marketingCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/marketing/demo",
  },
};

export const marketingFaqs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/page-2",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};

export const marketingSolutionsSlides = [
  {
    title: "Appointments",
    img: "Appointments1.png",
    link: "/products/appointments",
  },
  {
    title: "Restaurants",
    link: "/restaurant-pos",
    img: "Restaurant.png",
  },
  {
    title: "Virtual Terminal",
    link: "/products/virtual-terminal",
    img: "Virtual Terminal.png",
  },
  {
    title: "Loyalty",
    link: "/products/loyalty",
    img: "Loyalty.png",
  },
  {
    title: "Reserve",
    link: "/products/reserve",
    img: "Reserve.png",
  },
  {
    title: "Terminal",
    link: "/products/terminal",
    img: "Terminal.png",
  },
];
